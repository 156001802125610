import React from "react";
import { motion } from "framer-motion";

const links = [
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/adeadebimpe/",
  },
  {
    name: "Dribbble",
    url: "https://dribbble.com/ade_adebimpe",
  },
  {
    name: "Github",
    url: "https://github.com/adeadebimpe",
  },
];

const footerVariants = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 1},
  },
};

function Footer({ newStyle }) {
  return (
    <motion.footer
      variants={footerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className={`footer ${"footer__" + newStyle ? newStyle : ""}`}
    >
      <div className="container">
        <div className="footer__content">
          <h2>
            Let's <span>work</span> together
          </h2>
          <p>
            I’ll be happy to discuss new opportunities. Feel free to reach out
            to me any time.
          </p>
          <a
            href="mailto:hi@adeadebimpe.com"
            target="_blank"
            rel="noreferrer"
            className="footer__content__link"
          >
            Send Email
          </a>
        </div>
        <div className="footer__copyright">
          <ul className="footer__links">
            {links.map((item) => {
              return (
                <li key={item.name} className="footer__links__item">
                  <a href={item.url} target="_blank" rel="noreferrer noopener">
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <span>Adebowale Adebimpe - Copyright 2021</span>
        </div>
      </div>
    </motion.footer>
  );
}

export default Footer;
