import React, { Component } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion"

export default class Nav extends Component {
  state = {
    show: true,
    hideNav: false,
  };

  handleMenuOpen = () => {
    this.setState({
      show: !this.state.show
    });
  };

  render() {

    const links = [
      {
        name: "About",
        url: "/about",
      },
      {
        name: "Resume",
        url: "/",
      },
      {
        name: "Let's Chat",
        url: "/",
      },
    ];
  
    const { newStyle } = this.props;

    const navVariants = {
      hidden: {
        y: -20, 
        opacity: 0,
      },
      visible: {
        y: 0, 
        opacity: 1,
        transition:{delay: 0.5, duration: 0.5}
      }, 
    }

    return (
      <motion.nav 
      variants={navVariants}
      initial="hidden"
      animate="visible"
      className={`nav ${"nav__" + newStyle ? newStyle : ""} ${this.state.show ? "" : "open"}`}>
        <div className="container">
          <div className="nav__content">
            <div className="nav__logo">
              <Link to="/" className="logo">
                <svg
                  width="48"
                  height="37"
                  viewBox="0 0 48 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.4526 26.9447H39.6538C40.4083 26.9447 41.0867 27.3735 41.4238 28.0649L44.7974 34.9736H37.5963C36.8413 34.9736 36.1634 34.5449 35.8262 33.8535L32.4526 26.9447ZM42.9204 26.5986C42.4149 25.562 41.3971 24.9183 40.2658 24.9183H29.25L34.3297 35.3198C34.8356 36.3563 35.8525 37 36.9843 37H48L42.9204 26.5986Z"
                    fill="white"
                  />
                  <path
                    d="M28.695 1.95351L32.985 10.6039C33.2561 11.1497 33.2552 11.8016 32.9832 12.3475L29.2708 19.791L20.3993 1.95351H28.695ZM34.9463 10.1696L29.9016 0H17.25L29.269 24.1633L34.9431 12.785C35.3514 11.9669 35.3523 10.989 34.9463 10.1696Z"
                    fill="white"
                  />
                  <path
                    d="M22.6572 17.4422C22.1464 16.4651 21.1192 15.8571 19.9765 15.8571H9.75L14.3789 24.7116H16.6131L12.9834 17.7681H19.359C20.1203 17.7681 20.8053 18.1734 21.1457 18.8249L24.5165 25.2727H20.8814H14.6723L15.6711 27.1837H27.75L22.6572 17.4422Z"
                    fill="white"
                  />
                  <path
                    d="M17.0007 27.5844L18 25.6734H5.92146L0 37H10.2267C11.3694 37 12.3966 36.3926 12.9069 35.415L16.7079 28.1459H14.4738L11.3959 34.0323C11.0556 34.6838 10.3706 35.0891 9.60929 35.0891H3.23336L7.15631 27.5844H17.0007Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </div>
            <button
              className={`nav__hamburger ${this.state.show ? "" : "is-open"}`}
              onClick={this.handleMenuOpen}
              onKeyDown={this.handleMenuOpen}
            >
              <div className="hamburger__toggle"></div>
            </button>
            <ul className={`nav__links ${this.state.show ? "" : "open"}`}>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1GALce-_IRlaxtn7st72XxjDS1mU2zOz8/view?usp=sharing" target="_blank" rel="noreferrer noopener">Resume</a>
              </li>
              <li>
                <a href="mailto:hi@adeadebimpe.com">Let's Chat</a>
              </li>
             {/*  {links.map((item) => {
                return (
                  <li key={item.name}>
                    <Link to={item.url}>{item.name}</Link>
                  </li>
                );
              })} */}
            </ul>
          </div>
        </div>
      </motion.nav>
    );
  }
}
