import React from "react"
import PropTypes from "prop-types"

import Nav from './nav'
import Footer from "./footer"



const Layout = ({ pageTitle, newStyle, children }) => {
  return (
    <main className={`${pageTitle ? pageTitle : ""}`}>
      <Nav newStyle={newStyle} />
      {children}
      <Footer newStyle={newStyle} />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
